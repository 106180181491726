/**
 * Utility for using feature flags.
 */
class FeatureService {
  /**
   * @param {string[]} enabledFeatures list of current company enabled feature-flag keys
   */
  constructor(enabledFeatures) {
    this.enabledFeatures = enabledFeatures;
  }

  /**
   * Return if feature identified by the given name is enabled for current company.
   * @param {string} key
   * @return {boolean}
   */
  isEnabled(key) {
    return this.enabledFeatures.includes(key);
  }
}

FeatureService.JAKALA_FEATURES_NOVEMBER_BLOCK = 'jakala-features-november-block';
FeatureService.PUBLIC_API = 'public-api';
FeatureService.PIVOT_WEEKLY_TIMESHEET_EXPORT = 'pivot-weekly-timesheet-export';
FeatureService.PROBABILITY_FILTER_FINANCIALS = 'probability-filter-financials';
FeatureService.FTE = 'fte';

module.exports = FeatureService;
